import React from "react";
import { useFragment } from "react-relay";
import type {
  LiveTrainFragment_liveTrain$data,
  LiveTrainFragment_liveTrain$key,
} from "./__generated__/LiveTrainFragment_liveTrain.graphql";
import dateStrToHourMinute from "../datetime-converter";
import { TableCell } from "@mui/material";
import { isMobile } from "react-device-detect";
const graphql = require("babel-plugin-relay/macro");

type Props = {
  liveTrain: LiveTrainFragment_liveTrain$key;
};

const NO_PADDING = {
  padding: "0px 0px 0px 0px",
  marginBottom: "auto",
  marginTop: "auto",
};

const GREY = "#AAAAAA";

function LiveTrain(props: Props): React.ReactElement {
  const liveTrain = useFragment<LiveTrainFragment_liveTrain$key>(
    graphql`
      fragment LiveTrainFragment_liveTrain on LiveTrain {
        error
        stationFrom
        stationTo
        status
        platformAtRouteStart
        scheduledDepartureTime
        expectedDepartureTime
        scheduledArrivalTime
        expectedArrivalTime
        departureDelayMinutes
        tripDurationMinutes
        finalDestination
        desktopUrlToNationalRail
        mobileUrlToNationalRail
        trainAtStation
        departed
        branches
      }
    `,
    props.liveTrain
  );

  const styleText = {
    fontSize: 12,
    // fontFamily: "verdana",
    margin: 0,
  };

  const platform = getPlatform(liveTrain);
  let departureTimeComponent = getDepartureTimeComponent(liveTrain);
  let arrivalTimeComponent = getArrivalTimeComponent(liveTrain);

  return (
    <>
      <TableCell
        style={{
          ...styleText,
          textAlign: "left",
          width: "14%",
          padding: "0px 4px 0px 4px",
        }}
      >
        {departureTimeComponent}
      </TableCell>
      <TableCell
        style={{
          ...styleText,
          textAlign: "left",
          width: "61%",
          padding: "5px 4px 5px 4px",
        }}
      >
        {liveTrain.finalDestination}{" "}
        <a href={getTrainUrl(liveTrain)}>
          {liveTrain.branches ? "- splits" : ""}
        </a>
      </TableCell>
      <TableCell
        style={{
          ...styleText,
          textAlign: "center",
          width: "7%",
          padding: "0px 4px 0px 4px",
        }}
      >
        {platform}
      </TableCell>
      <TableCell
        style={{
          ...styleText,
          textAlign: "center",
          width: "11%",
          padding: "0px 4px 0px 4px",
        }}
      >
        {arrivalTimeComponent}
      </TableCell>
      <TableCell
        style={{
          ...styleText,
          textAlign: "right",
          width: "7%",
          padding: "0px 4px 0px 4px",
        }}
      >
        {liveTrain.tripDurationMinutes}m
      </TableCell>
    </>
  );
}

function getDepartureTimeComponent(
  liveTrain: LiveTrainFragment_liveTrain$data
) {
  const trainUrl = getTrainUrl(liveTrain);

  if (liveTrain.status === "CANCELLED") {
    const strikethrough_red = {
      textDecoration: "line-through",
      color: "red",
    };
    return (
      <a style={strikethrough_red} href={trainUrl}>
        {dateStrToHourMinute(liveTrain.scheduledDepartureTime)}
      </a>
    );
  }

  if (liveTrain.expectedDepartureTime !== liveTrain.scheduledDepartureTime) {
    return (
      <>
        <span style={{ color: GREY }}>
          {dateStrToHourMinute(liveTrain.scheduledDepartureTime)}
        </span>
        <p style={NO_PADDING}>
          <a href={trainUrl} style={{ color: "red" }}>
            {dateStrToHourMinute(liveTrain.expectedDepartureTime)}
          </a>
        </p>
      </>
    );
  }

  return (
    <a href={trainUrl}>
      {dateStrToHourMinute(liveTrain.scheduledDepartureTime)}
    </a>
  );
}

function getArrivalTimeComponent(liveTrain: LiveTrainFragment_liveTrain$data) {
  let additionalStyle = {};
  if (liveTrain.status === "CANCELLED") {
    additionalStyle = {
      textDecoration: "line-through",
      color: "red",
    };
  }
  if (liveTrain.scheduledArrivalTime !== liveTrain.expectedArrivalTime) {
    return (
      <>
        <span style={{ color: GREY }}>
          {dateStrToHourMinute(liveTrain.scheduledArrivalTime)}
        </span>
        <p style={{ ...NO_PADDING, ...additionalStyle, color: "red" }}>
          {dateStrToHourMinute(liveTrain.expectedArrivalTime)}
        </p>
      </>
    );
  }
  return (
    <span style={additionalStyle}>
      {dateStrToHourMinute(liveTrain.expectedArrivalTime)}
    </span>
  );
}

function getPlatform(liveTrain: LiveTrainFragment_liveTrain$data) {
  return liveTrain.platformAtRouteStart === ""
    ? "-"
    : liveTrain.platformAtRouteStart;
}

function getTrainUrl(liveTrain: LiveTrainFragment_liveTrain$data) {
  return isMobile
    ? liveTrain.mobileUrlToNationalRail
    : liveTrain.desktopUrlToNationalRail;
}

export default LiveTrain;
