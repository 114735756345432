import { RelayEnvironmentProvider } from "react-relay";
import "./App.css";
import RouteGroups from "./RouteGroups";
import environment from "../graphql/environment";
import { Suspense } from "react";
import SkeletonTable from "./SkeletonTable";
import ProjectHeader from "./ProjectHeader";
import Weather from "./Weather";

function App() {
  const style = {
    width: "400px",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  };

  // TODO top level query should start here instead of inside RouteGroups

  return (
    <div style={style}>
      <ProjectHeader />
      <RelayEnvironmentProvider environment={environment}>
        <Suspense fallback={<SkeletonTable />}>
          <RouteGroups />
          {/* TODO <PublicTransportTypes /> */}
          <Weather />
        </Suspense>
      </RelayEnvironmentProvider>
      <div style={{ height: "200px" }}></div>
    </div>
  );
}

export default App;
