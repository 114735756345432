import { Environment, Network, RecordSource, Store } from "relay-runtime";
import { RequestParameters } from "relay-runtime/lib/util/RelayConcreteNode";
import { Variables } from "relay-runtime/lib/util/RelayRuntimeTypes";
import TokenGenerator from "../auth/auth";
import { DEV_GRAPHQL } from "../dev";

async function fetchGraphQL(params: RequestParameters, variables: Variables) {
  // Dev
  const url = DEV_GRAPHQL
    ? "http://localhost:4000/graphql"
    : "https://api.carlosrodriguez.tech";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await TokenGenerator.instance.getIdToken()}`,
    },
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  });

  return await response.json();
}

export default new Environment({
  network: Network.create(fetchGraphQL),
  store: new Store(new RecordSource()),
});
