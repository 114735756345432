import React from "react";
import { useFragment } from "react-relay";
import type { RouteFragment_route$key } from "./__generated__/RouteFragment_route.graphql";
import LiveTrain from "./LiveTrain";
import { TableRow } from "@mui/material";
const graphql = require("babel-plugin-relay/macro");

type Props = {
  routeGroupId: string;
  route: RouteFragment_route$key;
};

function Route(props: Props): React.ReactElement {
  const route = useFragment<RouteFragment_route$key>(
    graphql`
      fragment RouteFragment_route on Route {
        liveTrains {
          ...LiveTrainFragment_liveTrain
        }
      }
    `,
    props.route
  );

  return (
    <>
      {route.liveTrains?.map((liveTrain: any) => (
        <TableRow key={Math.random()} style={{ padding: 0 }}>
          <LiveTrain liveTrain={liveTrain} />
        </TableRow>
      ))}
    </>
  );
}

export default Route;
