/**
 * @generated SignedSource<<580fdd54ad31796f2b16d139e04e76f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LiveTrainFragment_liveTrain$data = {
  readonly branches: boolean | null;
  readonly departed: boolean;
  readonly departureDelayMinutes: number | null;
  readonly desktopUrlToNationalRail: string;
  readonly error: any | null;
  readonly expectedArrivalTime: any | null;
  readonly expectedDepartureTime: any | null;
  readonly finalDestination: string | null;
  readonly mobileUrlToNationalRail: string;
  readonly platformAtRouteStart: string | null;
  readonly scheduledArrivalTime: any | null;
  readonly scheduledDepartureTime: any | null;
  readonly stationFrom: string;
  readonly stationTo: string;
  readonly status: string | null;
  readonly trainAtStation: boolean;
  readonly tripDurationMinutes: number | null;
  readonly " $fragmentType": "LiveTrainFragment_liveTrain";
};
export type LiveTrainFragment_liveTrain$key = {
  readonly " $data"?: LiveTrainFragment_liveTrain$data;
  readonly " $fragmentSpreads": FragmentRefs<"LiveTrainFragment_liveTrain">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveTrainFragment_liveTrain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stationFrom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stationTo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platformAtRouteStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledDepartureTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expectedDepartureTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledArrivalTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expectedArrivalTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "departureDelayMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tripDurationMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalDestination",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "desktopUrlToNationalRail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobileUrlToNationalRail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trainAtStation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "departed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "branches",
      "storageKey": null
    }
  ],
  "type": "LiveTrain",
  "abstractKey": null
};

(node as any).hash = "ecabdd04ce6027b3c1776be8c640f81d";

export default node;
