export default function ProjectHeader(): React.ReactElement {
  return (
    <p
      style={{
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "bold",
        padding: "6px 6px 6px 6px",
        margin: 0,
        backgroundColor: "#EEEEFF",
      }}
    >
      mv
    </p>
  );
}
