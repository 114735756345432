import React, { Suspense, useState } from "react";
import { loadQuery, usePreloadedQuery } from "react-relay";
import type {
  RouteGroupsQuery,
  RouteGroupsQuery$data,
} from "./__generated__/RouteGroupsQuery.graphql";
import environment from "../graphql/environment";
import RouteGroup from "./RouteGroup";
import {
  Divider,
  LinearProgress,
  List,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
const graphql = require("babel-plugin-relay/macro");

/*
TODO
Is there a way to have a parent empty query and then 2 child components running one query each?
I suspect this can be fixed if the top query queries for User and then a User has RouteGroups.
This way we can have the RouteGroups component querying for fragment on User "{routeGroups { id, ...}}"
YES! That must be the best solution. It needs backend changes first though.
*/
const routeGroupsQuery = graphql`
  query RouteGroupsQuery {
    routeGroups {
      id
      ...RouteGroupFragment_routeGroup
    }
    publicTransportTypes {
      id
      status
      color
      textColor
    }
  }
`;

const userQueryReference = loadQuery<RouteGroupsQuery>(
  environment,
  routeGroupsQuery,
  {}
);

function RouteGroups(): React.ReactElement {
  const data: RouteGroupsQuery$data = usePreloadedQuery<RouteGroupsQuery>(
    routeGroupsQuery,
    userQueryReference
  );

  const [visible, setVisible] = useState<Set<string>>(new Set<string>());

  function onClick(routeGroupId: string) {
    const cloned = new Set(visible.values());
    if (visible.has(routeGroupId)) {
      cloned.delete(routeGroupId);
    } else {
      cloned.add(routeGroupId);
    }
    setVisible(cloned);
  }

  const headerStyle = {
    // fontFamily: "verdana",
    fontSize: "14px",
  };

  // TODO how to decouple PublicTransportTypes from RouteGroups, they should be independent components
  // TODO this section should be loaded when opened like the other ones and also have a refresh button
  const publicTransportTypesStatusComponent = (
    <div key="publicTransportTypesStatus">
      <List
        dense={true}
        sx={{
          width: "100%",
          maxWidth: 400,
          padding: 0,
        }}
      >
        <ListItemButton
          style={{ paddingTop: 4, paddingBottom: 4, margin: 0 }}
          onClick={() => onClick("publicTransportTypes")}
        >
          <ListItemText
            style={{ margin: 0 }}
            primary={<p style={headerStyle}>{"TFL Status"}</p>}
          />
          {visible.has("publicTransportTypes") ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )}
        </ListItemButton>

        {/* TODO constant for this key */}

        <Table size="small">
          <TableBody>
            {visible.has("publicTransportTypes")
              ? data.publicTransportTypes?.map((type) => {
                  return (
                    type.status !== "Good service" && (
                      <TableRow>
                        <TableCell
                          style={{
                            backgroundColor: type.color ?? "",
                            color: type.textColor ?? "white",
                            width: "45%",
                            fontSize: 12,
                          }}
                        >
                          {type.id}
                        </TableCell>
                        <TableCell style={{ fontSize: 12 }}>
                          {type.status}
                        </TableCell>
                      </TableRow>
                    )
                  );
                })
              : null}

            {visible.has("publicTransportTypes") ? (
              <TableCell
                colSpan={2}
                style={{ fontSize: 12, textAlign: "center" }}
              >
                Lines with good service are hidden.{" "}
                <a href="https://tfl.gov.uk/tube-dlr-overground/status/">
                  See all lines here.
                </a>
              </TableCell>
            ) : null}
          </TableBody>
        </Table>
        <Divider />
      </List>
    </div>
  );

  return (
    <>
      {data.routeGroups?.map((routeGroup: any) => {
        const routeGroupHeader = <p style={headerStyle}>{routeGroup.id}</p>;

        return (
          <div key={routeGroup.id}>
            <List
              dense={false}
              sx={{
                width: "100%",
                maxWidth: 400,
                padding: 0,
              }}
            >
              <ListItemButton
                style={{ paddingTop: 4, paddingBottom: 4, margin: 0 }}
                onClick={() => onClick(routeGroup.id)}
              >
                <ListItemText
                  style={{ margin: 0 }}
                  primary={routeGroupHeader}
                />
                {visible.has(routeGroup.id) ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              {visible.has(routeGroup.id) ? (
                <Suspense fallback={<LinearProgress />}>
                  <RouteGroup routeGroup={routeGroup} />
                </Suspense>
              ) : null}
              <Divider />
            </List>
          </div>
        );
      })}
      {publicTransportTypesStatusComponent}
    </>
  );
}

export default RouteGroups;
