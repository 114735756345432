// TODO do this properly
export default function Weather(): React.ReactElement {
  return (
    <p>
      <a target="blank" href="https://www.bbc.co.uk/weather/2643743">
        &nbsp;&nbsp;&nbsp;&nbsp;Weather
      </a>
    </p>
  );
}
