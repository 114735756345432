import { Amplify, Auth } from "aws-amplify";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { DEV_AUTH } from "../dev";

let amplifyConfig = {
  aws_app_analytics: "disable",
  aws_user_pools: "enable",
  aws_user_pools_id: "eu-west-1_LoXZ5ZA8c",
  aws_user_pools_mfa_type: "ON",
  aws_user_pools_web_client_id: "3fes45kan3o48oj2qdk757bfvv",
  aws_user_settings: "enable",

  cookieStorage: {
    domain: ".carlosrodriguez.tech",
    expires: 60,
    sameSite: "strict",
    secure: true,
  },
};

if (DEV_AUTH) {
  amplifyConfig = {
    ...amplifyConfig,
    cookieStorage: {
      domain: "localhost",
      expires: 60,
      sameSite: "strict",
      secure: false,
    },
  };
}

Amplify.configure(amplifyConfig);

export default class TokenGenerator {
  private static _instance: TokenGenerator;

  public static get instance(): TokenGenerator {
    return this._instance || (this._instance = new this());
  }

  private constructor() {}

  public async getIdToken(): Promise<string> {
    if (DEV_AUTH) {
      return Auth.currentSession()
        .then((session: CognitoUserSession) => {
          return session.isValid()
            ? session.getIdToken().getJwtToken()
            : TokenGenerator.generateIdToken();
        })
        .catch((message: string) => {
          // console.log(message);
          return TokenGenerator.generateIdToken();
        });
    }

    return Auth.currentSession()
      .then((session: CognitoUserSession) => {
        if (session.isValid()) {
          return session.getIdToken().getJwtToken();
        }
        this.redirectToSignInPage();
        return "not signed in";
      })
      .catch((message: string) => {
        // console.log(message);
        this.redirectToSignInPage();
        return "not signed in";
      });
  }

  private redirectToSignInPage(): void {
    window.location.replace("https://www.carlosrodriguez.tech");
  }

  // Dev
  private static async generateIdToken(): Promise<string> {
    console.log("Generating token");
    let response: CognitoUser = await Auth.signIn(
      // TODO this shouldn't be in the production code. Can I have a dev properties file that is excluded from packaged.zip?
      "carloshenrique153@gmail.com",
      "Password#1"
    );
    return response.getSignInUserSession()!.getIdToken().getJwtToken();
  }
}
