import { Box, LinearProgress, Skeleton } from "@mui/material";

export default function SkeletonTable(): React.ReactElement {
  return (
    <>
      <LinearProgress />
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "99%",
          }}
        >
          <Skeleton style={{ margin: 3 }} variant="rounded" height={60} />
          <Skeleton
            style={{ margin: 3 }}
            animation="wave"
            variant="rounded"
            height={60}
          />
          <Skeleton
            style={{ margin: 3 }}
            animation={false}
            variant="rounded"
            height={60}
          />
          <Skeleton style={{ margin: 3 }} variant="rounded" height={60} />
          <Skeleton
            style={{ margin: 3 }}
            animation="wave"
            variant="rounded"
            height={60}
          />
          <Skeleton
            style={{ margin: 3 }}
            animation={false}
            variant="rounded"
            height={60}
          />
        </Box>
      </div>
    </>
  );
}
