import React, { useCallback, useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import Route from "./Route";
import {
  RouteGroupQuery,
  RouteGroupQuery$data,
} from "./__generated__/RouteGroupQuery.graphql";
import { RouteGroupFragment_routeGroup$key } from "./__generated__/RouteGroupFragment_routeGroup.graphql";
import {
  ListItemButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
const graphql = require("babel-plugin-relay/macro");

type Props = {
  routeGroup: RouteGroupFragment_routeGroup$key;
};

function RouteGroup(props: Props): React.ReactElement {
  const routeGroup = useFragment<RouteGroupFragment_routeGroup$key>(
    graphql`
      fragment RouteGroupFragment_routeGroup on RouteGroup {
        id
      }
    `,
    props.routeGroup
  );

  const [forceRefreshCount, setForceRefreshCount] = useState(0);
  const forceRefresh = useCallback(() => {
    setForceRefreshCount(forceRefreshCount + 1);
  }, [forceRefreshCount]);

  const data: RouteGroupQuery$data = useLazyLoadQuery<RouteGroupQuery>(
    graphql`
      query RouteGroupQuery($id: String!) {
        routeGroup(id: $id) {
          id
          routes {
            stationFrom
            stationTo
            urlToNationalRail
            liveTrains {
              __typename
            }
            ...RouteFragment_route
          }
        }
      }
    `,
    {
      id: routeGroup.id,
    },
    {
      // Force querying again every time the component is displayed (default seems to be "store-or-network")
      fetchPolicy: "network-only",
      // Force refreshing component whenever needed
      fetchKey: forceRefreshCount,
    }
  );

  return (
    <>
      {data.routeGroup?.routes?.map((route: any) => {
        let styleRouteName = {
          fontWeight: "bold",
          fontSize: 12,
        };
        if (
          route.liveTrains === undefined ||
          route.liveTrains === null ||
          route.liveTrains.length === 0
        ) {
          styleRouteName = {
            ...styleRouteName,
            ...{ textDecoration: "line-through" },
          };
        }

        return (
          <div key={Math.random()}>
            <ListItemButton style={{ padding: 0 }}>
              <TableContainer
                style={{
                  padding: "10px 0px 0px 0px ",
                  margin: 0,
                  width: "100%",
                }}
              >
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ width: "88%", padding: "4px 0px 14px 4px" }}
                        colSpan={4}
                      >
                        <a
                          style={styleRouteName}
                          href={route.urlToNationalRail}
                        >
                          {route.stationFrom}
                          {"\u00A0"}
                          {"\u00A0"}
                          {"\u2192"}
                          {"\u00A0"}
                          {"\u00A0"}
                          {route.stationTo}
                        </a>
                      </TableCell>
                      <TableCell
                        style={{
                          width: "12%",
                          padding: "0px 10px 0px 4px",
                          // textAlign: "right",
                        }}
                        onClick={() => forceRefresh()}
                      >
                        <RefreshIcon />
                      </TableCell>
                    </TableRow>
                    <Route routeGroupId={routeGroup.id} route={route} />
                  </TableBody>
                </Table>
              </TableContainer>
            </ListItemButton>
          </div>
        );
      })}
    </>
  );
}

export default RouteGroup;
