/**
 * @generated SignedSource<<d28a6c7ad79f6cec5b45d739a3fb6118>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteGroupFragment_routeGroup$data = {
  readonly id: string;
  readonly " $fragmentType": "RouteGroupFragment_routeGroup";
};
export type RouteGroupFragment_routeGroup$key = {
  readonly " $data"?: RouteGroupFragment_routeGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"RouteGroupFragment_routeGroup">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RouteGroupFragment_routeGroup",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "RouteGroup",
  "abstractKey": null
};

(node as any).hash = "4dd085a40688e68e8db4a89c762d6a3e";

export default node;
