/**
 * @generated SignedSource<<3a15623b2aa79285a871625ae4f9994e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteGroupsQuery$variables = {};
export type RouteGroupsQuery$data = {
  readonly publicTransportTypes: ReadonlyArray<{
    readonly color: string | null;
    readonly id: string;
    readonly status: string;
    readonly textColor: string | null;
  }> | null;
  readonly routeGroups: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"RouteGroupFragment_routeGroup">;
  }> | null;
};
export type RouteGroupsQuery = {
  response: RouteGroupsQuery$data;
  variables: RouteGroupsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "PublicTransportType",
  "kind": "LinkedField",
  "name": "publicTransportTypes",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textColor",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteGroupsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RouteGroup",
        "kind": "LinkedField",
        "name": "routeGroups",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RouteGroupFragment_routeGroup"
          }
        ],
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RouteGroupsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RouteGroup",
        "kind": "LinkedField",
        "name": "routeGroups",
        "plural": true,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v1/*: any*/)
    ]
  },
  "params": {
    "cacheID": "2575ba3435b66464eb3dafbf6e6f18e0",
    "id": null,
    "metadata": {},
    "name": "RouteGroupsQuery",
    "operationKind": "query",
    "text": "query RouteGroupsQuery {\n  routeGroups {\n    id\n    ...RouteGroupFragment_routeGroup\n  }\n  publicTransportTypes {\n    id\n    status\n    color\n    textColor\n  }\n}\n\nfragment RouteGroupFragment_routeGroup on RouteGroup {\n  id\n}\n"
  }
};
})();

(node as any).hash = "cb4a9d8840fa83960e6239e3a02456e3";

export default node;
