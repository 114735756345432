/**
 * @generated SignedSource<<20c2ebac9e0a2ccf918491f5f48389bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteGroupQuery$variables = {
  id: string;
};
export type RouteGroupQuery$data = {
  readonly routeGroup: {
    readonly id: string;
    readonly routes: ReadonlyArray<{
      readonly liveTrains: ReadonlyArray<{
        readonly __typename: "LiveTrain";
      }> | null;
      readonly stationFrom: string;
      readonly stationTo: string;
      readonly urlToNationalRail: string;
      readonly " $fragmentSpreads": FragmentRefs<"RouteFragment_route">;
    }> | null;
  } | null;
};
export type RouteGroupQuery = {
  response: RouteGroupQuery$data;
  variables: RouteGroupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stationFrom",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stationTo",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlToNationalRail",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RouteGroupQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RouteGroup",
        "kind": "LinkedField",
        "name": "routeGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Route",
            "kind": "LinkedField",
            "name": "routes",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LiveTrain",
                "kind": "LinkedField",
                "name": "liveTrains",
                "plural": true,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RouteFragment_route"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RouteGroupQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RouteGroup",
        "kind": "LinkedField",
        "name": "routeGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Route",
            "kind": "LinkedField",
            "name": "routes",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LiveTrain",
                "kind": "LinkedField",
                "name": "liveTrains",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "error",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "platformAtRouteStart",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scheduledDepartureTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expectedDepartureTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scheduledArrivalTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expectedArrivalTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "departureDelayMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tripDurationMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "finalDestination",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "desktopUrlToNationalRail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mobileUrlToNationalRail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "trainAtStation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "departed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "branches",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e45aefb0c3045aa11a6ca10fde837f31",
    "id": null,
    "metadata": {},
    "name": "RouteGroupQuery",
    "operationKind": "query",
    "text": "query RouteGroupQuery(\n  $id: String!\n) {\n  routeGroup(id: $id) {\n    id\n    routes {\n      stationFrom\n      stationTo\n      urlToNationalRail\n      liveTrains {\n        __typename\n      }\n      ...RouteFragment_route\n    }\n  }\n}\n\nfragment LiveTrainFragment_liveTrain on LiveTrain {\n  error\n  stationFrom\n  stationTo\n  status\n  platformAtRouteStart\n  scheduledDepartureTime\n  expectedDepartureTime\n  scheduledArrivalTime\n  expectedArrivalTime\n  departureDelayMinutes\n  tripDurationMinutes\n  finalDestination\n  desktopUrlToNationalRail\n  mobileUrlToNationalRail\n  trainAtStation\n  departed\n  branches\n}\n\nfragment RouteFragment_route on Route {\n  liveTrains {\n    ...LiveTrainFragment_liveTrain\n  }\n}\n"
  }
};
})();

(node as any).hash = "a7c4afdc6859c456be0e300b87fb7f94";

export default node;
